/* style.css */


/**
* Template Name: iPortfolio
* Template URL: https://bootstrapmade.com/iportfolio-bootstrap-portfolio-websites-template/
* Updated: Mar 17 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	font-family: "Open Sans", sans-serif;
	color: #272829;
  }
  
  a {
	color: #149ddd;
	text-decoration: none;
  }
  
  a:hover {
	color: #37b3ed;
	text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
	font-family: "Raleway", sans-serif;
  }
  
  /* Loader */
.loading {
	-webkit-animation: sk-scaleout 1s infinite ease-in-out;
	animation: sk-scaleout 1s infinite ease-in-out;
	background-color: #2eafec;
	border-radius: 100%;
	height: 6em;
	width: 6em;
}

.preloader{
	align-items: center;
	background-color: white;
	display: flex;
	height: 100vh;
	justify-content: center;
	width: 100%;
}

@keyframes sk-scaleout {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 0.5;
		transform: scale(1);
	}
}
/*#pre-load {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
  }
  
  #pre-load:before {
	  content: "";
	  position: fixed;
	  top: calc(50% - 30px);
	  left: calc(50% - 30px);
	  border: 6px solid #f2f2f2;
	  border-top: 6px solid #2D8E5D ;
	  border-radius: 50%;
	  width: 60px;
	  height: 60px;
	  -webkit-animation: animate-preloader 1s linear infinite;
	  animation: animate-preloader 1s linear infinite;
  }
  
  @-webkit-keyframes animate-preloader {
	  0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	  }
	  100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	  }
	  }
	  @keyframes animate-preloader {
	  0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	  }
	  100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	  }
  }
   */
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 15px;
	bottom: 15px;
	z-index: 996;
	background: #149ddd;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	transition: all 0.4s;
  }
  
  .back-to-top i {
	font-size: 28px;
	color: #fff;
	line-height: 0;
  }
  
  .back-to-top:hover {
	background: #2eafec;
	color: #fff;
  }
  
  .back-to-top.active {
	visibility: visible;
	opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 300px;
	transition: all ease-in-out 0.5s;
	z-index: 9997;
	transition: all 0.5s;
	padding: 0 15px;
	background: #040b14;
	overflow-y: auto;
  }
  
  #header .profile img {
	margin: 15px auto;
	display: block;
	width: 150px;
	/* border: 8px solid #2c2f3f; */
  }
  
  #header .profile h1 {
	font-size: 24px;
	margin: 0;
	padding: 0;
	font-weight: 600;
	-moz-text-align-last: center;
	text-align-last: center;
	font-family: "Poppins", sans-serif;
  }
  
  #header .profile h1 a,
  #header .profile h1 a:hover {
	color: #fff;
	text-decoration: none;
  }
  
  #header .profile .social-links a {
	font-size: 18px;
	display: inline-block;
	background: #212431;
	color: #fff;
	line-height: 1;
	padding: 8px 0;
	margin-right: 4px;
	border-radius: 50%;
	text-align: center;
	width: 36px;
	height: 36px;
	transition: 0.3s;
  }
  
  #header .profile .social-links a:hover {
	background: #149ddd;
	color: #fff;
	text-decoration: none;
  }
  
  #main {
	margin-left: 300px;
  }
  
  .container,
  .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
  }
  
  @media (max-width: 1199px) {
	#header {
	  left: -300px;
	}
  
	#main {
	  margin-left: 0;
	}
  
	.container,
	.container-fluid {
	  padding-left: 12px;
	  padding-right: 12px;
	}
  }
  
  .visible{
		left: 0 !important; 
  }

  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  .nav-menu {
	padding: 30px 0 100px 0;
  }
  
  .nav-menu * {
	margin: 0;
	padding: 0;
	list-style: none;
  }
  
  .nav-menu>ul>li {
	position: relative;
	white-space: nowrap;
  }
  
  .nav-menu a,
  .nav-menu a:focus {
	display: flex;
	align-items: center;
	color: #a8a9b4;
	padding: 12px 15px;
	margin-bottom: 8px;
	transition: 0.3s;
	font-size: 15px;
  }
  
  .nav-menu a i,
  .nav-menu a:focus i {
	font-size: 24px;
	padding-right: 8px;
	color: #6f7180;
  }
  
  .nav-menu a:hover,
  .nav-menu .active,
  .nav-menu .active:focus,
  .nav-menu li:hover>a {
	text-decoration: none;
	color: #fff;
  }
  
  .nav-menu a:hover i,
  .nav-menu .active i,
  .nav-menu .active:focus i,
  .nav-menu li:hover>a i {
	color: #149ddd;
  }
  
  /* Mobile Navigation */
  .mobile-nav-toggle {
	position: fixed;
	right: 15px;
	top: 15px;
	z-index: 9998;
	border: 0;
	font-size: 24px;
	transition: all 0.4s;
	outline: none !important;
	background-color: #149ddd;
	color: #fff;
	width: 40px;
	height: 40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	border-radius: 50px;
	cursor: pointer;
  }
  

  .mobile-nav-active {
	overflow: hidden;
  }
  
  .mobile-nav-active #header {
	left: 0;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
	width: 100%;
	height: 97vh;
	background: url("/src/assets/img/About-me.jpg") top center;
	background-size: cover;
  }
  
  #hero:before {
	content: "";
	background: rgba(5, 13, 24, 0.3);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
  }
  
  #hero .hero-container {
	position: relative;
	z-index: 2;
	min-width: 300px;
  }
  
  #hero h1 {
	margin: 0 0 10px 0;
	font-size: 64px;
	font-weight: 700;
	line-height: 56px;
	color: #fff;
  }
  
  #hero p {
	color: #fff;
	margin-bottom: 50px;
	font-size: 26px;
	font-family: "Poppins", sans-serif;
  }
  
  #hero p span {
	color: #fff;
	padding-bottom: 4px;
	letter-spacing: 1px;
	border-bottom: 3px solid #149ddd;
  }
  
  @media (min-width: 1024px) {
	#hero {
	  background-attachment: fixed;
	}
  }
  
  @media (max-width: 768px) {
	#hero h1 {
	  font-size: 28px;
	  line-height: 36px;
	}
  
	#hero h2 {
	  font-size: 18px;
	  line-height: 24px;
	  margin-bottom: 30px;
	}
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  /* section {
	padding: 60px 0;
	overflow: hidden;
  }
  
  .section-bg {
	background: #f5f8fd;
  } */
  
  .section-title {
	padding-bottom: 30px;
  }
  
  .section-title h2 {
	font-size: 32px;
	font-weight: bold;
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
	color: #173b6c;
  }
  
  .section-title h2::after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 3px;
	background: #149ddd;
	bottom: 0;
	/* left: 0; */
  }
  
  .section-title p {
	margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .content h3 {
	font-weight: 700;
	font-size: 26px;
	color: #173b6c;
  }
  
  .about .content ul {
	list-style: none;
	padding: 0;
  }
  
  /* .about .content ul li {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
  } */
  
  .about .content ul strong {
	margin-right: 10px;
  }
  
  .about .content ul i {
	font-size: 16px;
	margin-right: 5px;
	color: #149ddd;
	line-height: 0;
  }
  
  .about .content p:last-child {
	margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Facts
  --------------------------------------------------------------*/
  .facts {
	padding-bottom: 30px;
  }
  
  .facts .count-box {
	padding: 30px;
	width: 100%;
  }
  
  .facts .count-box i {
	display: block;
	font-size: 44px;
	color: #149ddd;
	float: left;
	line-height: 0;
  }
  
  .facts .count-box span {
	font-size: 48px;
	line-height: 40px;
	display: block;
	font-weight: 700;
	color: #050d18;
	margin-left: 60px;
  }
  
  .facts .count-box p {
	padding: 15px 0 0 0;
	margin: 0 0 0 60px;
	font-family: "Raleway", sans-serif;
	font-size: 14px;
	color: #122f57;
  }
  
  .facts .count-box a {
	font-weight: 600;
	display: block;
	margin-top: 20px;
	color: #122f57;
	font-size: 15px;
	font-family: "Poppins", sans-serif;
	transition: ease-in-out 0.3s;
  }
  
  .facts .count-box a:hover {
	color: #1f5297;
  }
  
  /*--------------------------------------------------------------
  # Skills
  --------------------------------------------------------------*/
  /* .skills .progress {
	height: 60px;
	display: block;
	background: none;
	border-radius: 0;
  }
  
  .skills .progress .skill {
	padding: 0;
	margin: 0 0 6px 0;
	text-transform: uppercase;
	display: block;
	font-weight: 600;
	font-family: "Poppins", sans-serif;
	color: #050d18;
  }
  
  .skills .progress .skill .val {
	float: right;
	font-style: normal;
  }
  
  .skills .progress-bar-wrap {
	background: #dce8f8;
	height: 10px;
  }
  
  .skills .progress-bar {
	width: 1px;
	height: 10px;
	transition: 0.9s;
	background-color: #149ddd;
  }
  
  /*-------------------------------------------------------------- */
  /*# Resume */
  /*--------------------------------------------------------------*/
  .resume .resume-title {
	font-size: 26px;
	font-weight: 700;
	margin-top: 20px;
	margin-bottom: 20px;
	color: #050d18;
  }
  
  .resume .resume-item {
	padding: 0 0 20px 20px;
	margin-top: -2px;
	border-left: 2px solid #1f5297;
	position: relative;
  }
  
  .resume .resume-item h4 {
	line-height: 18px;
	font-size: 18px;
	font-weight: 600;
	/* text-transform: uppercase; */
	font-family: "Poppins", sans-serif;
	color: #050d18;
	margin-bottom: 10px;
  }
  
  .resume .resume-item h5 {
	font-size: 16px;
	background: #e4edf9;
	padding: 5px 15px;
	display: inline-block;
	font-weight: 600;
	margin-bottom: 10px;
  }
  
  .resume .resume-item ul {
	padding-left: 20px;
  }
  
  .resume .resume-item ul li {
	padding-bottom: 10px;
  }
  
  .resume .resume-item:last-child {
	padding-bottom: 0;
  }
  
  .resume .resume-item::before {
	content: "";
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50px;
	left: -9px;
	top: 0;
	background: #fff;
	border: 2px solid #1f5297;
  }
  
  /*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
	margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
	padding: 0;
	margin: 0 auto 35px auto;
	list-style: none;
	text-align: center;
	background: #fff;
	border-radius: 50px;
	padding: 2px 15px;
  }
  
  .portfolio #portfolio-flters li {
	cursor: pointer;
	display: inline-block;
	padding: 10px 15px 8px 15px;
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	color: #272829;
	margin-bottom: 5px;
	transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
	color: #149ddd;
  }
  
  .portfolio #portfolio-flters li:last-child {
	margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
	transition: 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
  }
  
  .portfolio .portfolio-wrap::before {
	content: "";
	background: rgba(255, 255, 255, 0.5);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	transition: all ease-in-out 0.3s;
	z-index: 2;
	opacity: 0;
  }
  
  .portfolio-links {
	opacity: 1;
	left: 0;
	right: 0;
	bottom: 0px;
	z-index: 3;
	position: relative;
	transition: all ease-in-out 0.3s;
	display: flex;
	justify-content: center;
  }
  
   .portfolio-links a {
	color: #fff;
	font-size: 28px;
	text-align: center;
	background: rgba(20, 157, 221, 0.75);
	transition: 0.3s;
	width: 100%;
  }

   .portfolio-links button {
	color: #fff;
	font-size: 28px;
	text-align: center;
	background: rgba(20, 157, 221, 0.75);
	transition: 0.3s;
	width: 100%;
	border: none;
  }
  
  .portfolio .portfolio-wrap .portfolio-links a:hover {
	background: rgba(20, 157, 221, 0.95);
  }

  .portfolio .portfolio-wrap .portfolio-links button:hover {
	background: rgba(20, 157, 221, 0.95);
  }
  
  .portfolio .portfolio-wrap .portfolio-links a+a {
	border-left: 1px solid #37b3ed;
  }
  
  .portfolio .portfolio-wrap:hover::before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 1;
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-links {
	opacity: 1;
	bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
  .portfolio-details {
	padding-top: 40px;
  }
  
  .portfolio-details .portfolio-details-slider img {
	width: 100%;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination {
	margin-top: 20px;
	position: relative;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: #fff;
	opacity: 1;
	border: 1px solid #149ddd;
  }
  
  .portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
	background-color: #149ddd;
  }
  
  .portfolio-details .portfolio-info {
	padding: 30px;
	box-shadow: 0px 0 30px rgba(5, 13, 24, 0.08);
  }
  
  .portfolio-details .portfolio-info h3 {
	font-size: 22px;
	font-weight: 700;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #eee;
  }
  
  .portfolio-details .portfolio-info ul {
	list-style: none;
	padding: 0;
	font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li+li {
	margin-top: 10px;
  }
  
  .portfolio-details .portfolio-description {
	padding-top: 30px;
  }
  
  .portfolio-details .portfolio-description h2 {
	font-size: 26px;
	font-weight: 700;
	margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
	padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
	margin-bottom: 20px;
  }
  
  .services .icon {
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 54px;
	height: 54px;
	background: #149ddd;
	border-radius: 50%;
	transition: 0.5s;
	border: 1px solid #149ddd;
  }
  
  .services .icon i {
	color: #fff;
	font-size: 24px;
	line-height: 0;
  }
  
  .services .icon-box:hover .icon {
	background: #fff;
  }
  
  .services .icon-box:hover .icon i {
	color: #149ddd;
  }
  
  .services .title {
	margin-left: 80px;
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 18px;
  }
  
  .services .title a {
	color: #343a40;
  }
  
  .services .title a:hover {
	color: #149ddd;
  }
  
  .services .description {
	margin-left: 80px;
	line-height: 24px;
	font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonials-carousel,
  .testimonials .testimonials-slider {
	overflow: hidden;
  }
  
  .testimonials .testimonial-item {
	box-sizing: content-box;
	text-align: center;
	min-height: 320px;
  }
  
  .testimonials .testimonial-item .testimonial-img {
	width: 90px;
	border-radius: 50%;
	margin: 0 auto;
  }
  
  .testimonials .testimonial-item h3 {
	font-size: 18px;
	font-weight: bold;
	margin: 10px 0 5px 0;
	color: #111;
  }
  
  .testimonials .testimonial-item h4 {
	font-size: 14px;
	color: #999;
	margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
	color: #c3e8fa;
	font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
	display: inline-block;
	left: -5px;
	position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
	display: inline-block;
	right: -5px;
	position: relative;
	top: 10px;
  }
  
  .testimonials .testimonial-item p {
	font-style: italic;
	margin: 0 15px 15px 15px;
	padding: 20px;
	background: #fff;
	position: relative;
	margin-bottom: 35px;
	border-radius: 6px;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .testimonials .testimonial-item p::after {
	content: "";
	width: 0;
	height: 0;
	border-top: 20px solid #fff;
	border-right: 20px solid transparent;
	border-left: 20px solid transparent;
	position: absolute;
	bottom: -20px;
	left: calc(50% - 20px);
  }
  
  .testimonials .swiper-pagination {
	margin-top: 20px;
	position: relative;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background-color: #fff;
	opacity: 1;
	border: 1px solid #149ddd;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
	background-color: #149ddd;
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact {
	padding-bottom: 130px;
  }
  
  .contact .info {
	padding: 30px;
	background: #fff;
	width: 100%;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .info i {
	font-size: 20px;
	color: #149ddd;
	float: left;
	width: 44px;
	height: 44px;
	background: #dff3fc;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
  }
  
  .contact .info h4 {
	padding: 0 0 0 60px;
	font-size: 22px;
	font-weight: 600;
	margin-bottom: 5px;
	color: #050d18;
  }
  
  .contact .info p {
	padding: 0 0 10px 60px;
	margin-bottom: 20px;
	font-size: 14px;
	color: #173b6c;
  }
  
  .contact .info .email p {
	padding-top: 5px;
  }
  
  .contact .info .social-links {
	padding-left: 60px;
  }
  
  .contact .info .social-links a {
	font-size: 18px;
	display: inline-block;
	background: #333;
	color: #fff;
	line-height: 1;
	padding: 8px 0;
	border-radius: 50%;
	text-align: center;
	width: 36px;
	height: 36px;
	transition: 0.3s;
	margin-right: 10px;
  }
  
  .contact .info .social-links a:hover {
	background: #149ddd;
	color: #fff;
  }
  
  .contact .info .email:hover i,
  .contact .info .address:hover i,
  .contact .info .phone:hover i {
	background: #149ddd;
	color: #fff;
  }
  
  .contact .php-email-form {
	width: 100%;
	padding: 30px;
	background: #fff;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  }
  
  .contact .php-email-form .form-group {
	padding-bottom: 8px;
  }
  
  .contact .php-email-form .validate {
	display: none;
	color: red;
	margin: 0 0 15px 0;
	font-weight: 400;
	font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
	display: none;
	color: #fff;
	background: #ed3c0d;
	text-align: left;
	padding: 15px;
	font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
	margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
	display: none;
	color: #fff;
	background: #18d26e;
	text-align: center;
	padding: 15px;
	font-weight: 600;
  }
  
  .contact .php-email-form .loading {
	display: none;
	background: #fff;
	text-align: center;
	padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
	content: "";
	display: inline-block;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 10px -6px 0;
	border: 3px solid #18d26e;
	border-top-color: #eee;
	animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form .form-group {
	margin-bottom: 15px;
  }
  
  .contact .php-email-form label {
	padding-bottom: 8px;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
	border-radius: 0;
	box-shadow: none;
	font-size: 14px;
  }
  
  .contact .php-email-form input {
	height: 44px;
  }
  
  .contact .php-email-form textarea {
	padding: 10px 15px;
  }
  
  .contact .php-email-form button[type=submit] {
	background: #149ddd;
	border: 0;
	padding: 10px 24px;
	color: #fff;
	transition: 0.4s;
	border-radius: 4px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
	background: #37b3ed;
  }
  
  @keyframes animate-loading {
	0% {
	  transform: rotate(0deg);
	}
  
	100% {
	  transform: rotate(360deg);
	}
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
	padding: 20px 0;
	background: #f9f9f9;
  }
  
  .breadcrumbs h2 {
	font-size: 26px;
	font-weight: 300;
  }
  
  .breadcrumbs ol {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 15px;
  }
  
  .breadcrumbs ol li+li {
	padding-left: 10px;
  }
  
  .breadcrumbs ol li+li::before {
	display: inline-block;
	padding-right: 10px;
	color: #0e2442;
	content: "/";
  }
  
  @media (max-width: 768px) {
	.breadcrumbs .d-flex {
	  display: block !important;
	}
  
	.breadcrumbs ol {
	  display: block;
	}
  
	.breadcrumbs ol li {
	  display: inline-block;
	}
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
	padding: 15px;
	color: #f4f6fd;
	font-size: 14px;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 300px;
	z-index: 9999;
	background: #040b14;
  }
  
  #footer .copyright {
	text-align: center;
  }
  
  #footer .credits {
	padding-top: 5px;
	text-align: center;
	font-size: 13px;
	color: #eaebf0;
  }
  
  @media (max-width: 1199px) {
	#footer {
	  position: static;
	  width: auto;
	  padding-right: 20px 15px;
	}
  }



/* body { 
	font-family: 'Arial', sans-serif; 
	background-color: #f8f9fa; 
	color: #ffffff; 
} 


.container { 
	max-width: 1200px; 
	margin: 0 auto; 
}  */


  
/* About Section Styles */
/* #about { 
	padding: 20px 0; 
} 

.section-title { 
	font-size: 2.5rem; 
	color: #17a2b8; 
	margin-bottom: 20px; 
} 

.section-description { 
	font-size: 1.2rem; 
	line-height: 1.6; 
	color: #555; 
} 

.card { 
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
	transition: transform 0.3s; 
} 

.card:hover { 
	transform: scale(1.05); 
} 

.resume-download-btn { 
	display: inline-block; 
	padding: 10px 20px; 
	font-size: 16px; 
	font-weight: bold; 
	text-align: center; 
	text-decoration: none; 
	background-color: #007bff; 
	color: #fff; 
	border-radius: 5px; 
	transition: background-color 0.3s; 
} 

.resume-download-btn:hover { 
	background-color: #0056b3; 
} 

.profile-image-container { 
	overflow: hidden; 
	border-radius: 50%; 
	margin: auto; 
	width: 200px; 
	height: 200px; 
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
} 

.profile-image { 
	width: 100%; 
	height: 100%; 
	object-fit: cover; 
}  */

/* Skills Component Styles */
/* .skills-container { 
	margin-top: 40px; 
} 

.skills-title { 
	font-size: 1.8rem; 
	color: #17a2b8; 
} 

.skills-list { 
	list-style: none; 
	padding: 0; 
	margin-top: 15px; 
} 

.skills-list li { 
	background-color: #17a2b8; 
	color: #fff; 
	border-radius: 5px; 
	padding: 8px 12px; 
	margin-bottom: 8px; 
	display: inline-block; 
	margin-right: 10px; 
} 

.skills-list li:last-child { 
	margin-right: 0; 
}  */

/* Projects Section Styles */
/* #projects { 
	padding: 0px 0; 
} 

.section-title { 
	font-size: 2.5rem; 
	color: #17a2b8; 
	margin-top: 20px; 
	margin-bottom: 20px; 
}  */

/* Project Card Styles */
/* .card { 
	border: none; 
	transition: transform 0.3s; 
} 

.card:hover { 
	transform: scale(1.05); 
} 

.card-title { 
	font-size: 1.5rem; 
	color: #333; 
} 

.card-text { 
	font-size: 1.2rem; 
	color: #555; 
}  */


/* Resume Section Styles */
/* #resume { 
	padding: 10px 0; 
} 

#resume h2 { 
	font-size: 2.5rem; 
	color: #17a2b8; 
	margin-bottom: 20px; 
} 

#resume p { 
	font-size: 1.2rem; 
	line-height: 1.6; 
	color: #555; 
} 

.resume-download-btn { 
	display: inline-block; 
	padding: 10px 20px; 
	font-size: 1.2rem; 
	text-align: center; 
	text-decoration: none; 
	background-color: #28a745; 
	color: #fff; 
	border-radius: 5px; 
	transition: background-color 0.3s; 
} 

.resume-download-btn:hover { 
	background-color: #218838; 
}  */



/* Contact Section Styles */
/* #contact { 
	padding: 10px 0; 
} 

#contact .section-title { 
	font-size: 2.5rem; 
	color: #17a2b8; 
	margin-bottom: 20px; 
} 

#contact .section-description { 
	font-size: 1.2rem; 
	line-height: 1.6; 
	color: #555; 
} 

.contact-list { 
	list-style: none; 
	padding: 0; 
	display: flex; 
	flex-direction: column; 
} 

.contact-list li { 
	margin-bottom: 15px; 
} 

.contact-icon { 
	margin-right: 10px; 
	font-size: 24px; 
	color: #17a2b8; 
} 

.contact-list a { 
	color: #333; 
	text-decoration: none; 
}  */

/* Hover effect for contact links */
/* .contact-list a:hover { 
	color: #17a2b8; 
}  */


/* Skills Section Styles */
/* .skills-title { 
	font-size: 1.8rem; 
	color: #17a2b8; 
	margin-top: 30px; 
} 

.skills-list { 
	list-style: none; 
	padding: 0; 
	margin-top: 15px; 
} 

.skills-list li { 
	background-color: #17a2b8; 
	color: #fff; 
	border-radius: 5px; 
	padding: 8px 12px; 
	margin-bottom: 8px; 
	display: inline-block; 
	margin-right: 10px; 
} 

.skills-list li:last-child { 
	margin-right: 0; 
}  */

/* Button Styles */
/* .btn-primary { 
	background-color: #17a2b8; 
	color: #fff; 
	border: none; 
	padding: 10px 20px; 
	font-size: 1.2rem; 
	text-decoration: none; 
	border-radius: 5px; 
	display: inline-block; 
	margin-top: 20px; 
	transition: background-color 0.3s ease; 
} 

.btn-primary:hover { 
	background-color: #136b7d; 
}  */


/* Footer Styles */
/* .footer { 
	background-color: #343a40; 
	color: #fff; 
	padding: 20px 0; 
	position: relative; 
	bottom: 0; 
	width: 100%; 
} 

.footer p { 
	margin-bottom: 0; 
} 

.social-icons { 
	display: flex; 
	justify-content: flex-end; 
} 

.social-icons li { 
	margin-right: 10px; 
} 

.social-icons a { 
	color: #fff; 
	font-size: 20px; 
	text-decoration: none; 
}  */

/* Hover effect for social icons */
/* .social-icons a:hover { 
	color: #17a2b8; 
}  */


/* Responsive Styles */
/* @media (max-width: 768px) { 
	h2 { 
		font-size: 2rem; 
	} 

	p { 
		font-size: 1rem; 
	} 
} 


.breadcrumb { 
	background-color: #f8f9fa;  */
	/* Breadcrumb background color */
	/* padding: 10px; 
	border-radius: 8px; 
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 

} 

.breadcrumb-item a { 
	color: #007bff;  */
	/* Breadcrumb link color */
	/* text-decoration: none; 

} 

.breadcrumb-item a:hover { 
	text-decoration: underline; 
} 

.breadcrumb-item.active { 
	color: #343a40;  */
	/* Active breadcrumb item color */
	/* font-weight: bold; 
} 

.breadcrumb-item span::before { 
	display: none;  */
	/* Remove separator for the last breadcrumb */
/* } */
